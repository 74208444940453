<template>
  <span>
    <v-icon small v-if="service.workout" left>mdi-weight-lifter</v-icon>
    <v-chip v-if="service.timed" outlined dark class="mr-1" small>
      <v-icon small left>mdi-clock-check-outline</v-icon>
      <span>{{settings.spendmin}}</span>
      <span>min</span>
    </v-chip>
    <!-- <v-chip v-if="service.scalar" outlined dark class="mr-1" small>
      <v-icon small left>mdi-counter</v-icon>
      <span>{{settings.vcount}}</span>
    </v-chip> -->
    <v-chip v-if="settings.visits && settings.vcount" outlined dark class="mr-1" small>
      <v-icon small left>mdi-counter</v-icon>
      <span>{{settings.vcount}}</span>
    </v-chip>
    <span>{{service.name}}</span>
  </span>
</template>

<script>
export default {
  name: "ServiceChip",
  props: { item: Object, sett: Object },
  computed: {
    service() {
      return this.item;
    },
    settings() {
      try {
        return this.sett["s" + this.item.idx];
      } catch (error) {
        return {};
      }
    },
  },
};
</script>

<style>
</style>